var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _c(
            "v-tooltip",
            {
              attrs: { right: "", "max-width": "500", color: "primary" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-icon",
                        _vm._g(_vm._b({}, "v-icon", attrs, false), on),
                        [_vm._v("mdi-help-circle-outline")]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("span", [
                _vm._v(
                  'Aqui você acompanha o processamento dos arquivos enviados. Para gerar e baixar relatórios, acesse a opção "PIS/COFINS" no menu principal '
                ),
              ]),
            ]
          ),
          _c("v-spacer"),
          _c("v-spacer"),
          _c("v-text-field", {
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Pesquisar...",
              "single-line": "",
              "hide-details": "",
              dense: "",
            },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
        ],
        1
      ),
      _c(
        "v-flex",
        { staticStyle: { overflow: "hidden" }, attrs: { md12: "" } },
        [
          _c("v-data-table", {
            attrs: {
              loading: _vm.loading,
              "loading-text": "Carregando informações...",
              "show-expand": "",
              headers: _vm.headers,
              items: _vm.uploads,
              search: _vm.search,
              "sort-by": "created_at",
              "sort-desc": true,
              "custom-sort": _vm.customSort,
              "must-sort": true,
              "footer-props": {
                "items-per-page-options": [10, 25, 50],
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.fileNameSliced",
                  fn: function ({ item }) {
                    return [
                      _vm._t("idCol", function () {
                        return [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { right: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            _vm._b({}, "span", attrs, false),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { left: "" } },
                                              [_vm._v("mdi-folder-zip-outline")]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(item.fileNameSliced) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v(_vm._s(item.fileName))])]
                          ),
                        ]
                      }),
                    ]
                  },
                },
                {
                  key: "item.amountOfDocuments",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "v-chip",
                        {
                          staticClass: "blink",
                          attrs: { color: "darkgrey", dark: "", label: "" },
                        },
                        [_vm._v(" " + _vm._s(item.amountOfDocuments) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: "item.statusMessage",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "v-chip",
                        {
                          staticClass: "blink",
                          attrs: { color: item.statusColor, dark: "" },
                        },
                        [_vm._v(" " + _vm._s(item.statusMessage) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: "expanded-item",
                  fn: function ({ headers, item: childItem }) {
                    return [
                      _c(
                        "td",
                        { attrs: { colspan: headers.length } },
                        [
                          _c("v-data-table", {
                            attrs: {
                              dense: "",
                              "hide-default-footer": "",
                              headers: _vm.detailHeaders,
                              items: childItem.details,
                              "sort-by": "establishment",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.subStatusMessage",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          staticClass: "blink",
                                          staticStyle: { color: "#393939" },
                                          attrs: {
                                            color: item.subStatusColor,
                                            small: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.subStatusMessage) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.fileName",
                                  fn: function ({ item }) {
                                    return [
                                      _vm._t("fileNameCol", function () {
                                        return [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { right: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "span",
                                                          _vm._g(
                                                            _vm._b(
                                                              {},
                                                              "span",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  left: "",
                                                                  small: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-file-document-outline"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.fileName
                                                                ) +
                                                                " "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.file_name)),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.errorDialog,
            callback: function ($$v) {
              _vm.errorDialog = $$v
            },
            expression: "errorDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5 white--text error" },
                [
                  _c(
                    "v-icon",
                    { staticClass: "white--text", attrs: { left: "" } },
                    [_vm._v("mdi-alert")]
                  ),
                  _vm._v(" Algo deu errado "),
                ],
                1
              ),
              _c("br"),
              _c("v-card-text", { staticClass: "text-h6" }, [
                _vm._v(
                  " Tente novamente mais tarde ou entre em contato com o administrador "
                ),
              ]),
              _c(
                "v-card-actions",
                { staticClass: "justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "secondary lighten-2",
                      attrs: { text: "", id: "IdBtnClose" },
                      on: {
                        click: function ($event) {
                          _vm.errorDialog = false
                        },
                      },
                    },
                    [_vm._v(" fechar ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }