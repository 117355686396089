<template>
  <v-card>
    <v-card-title>
      <v-tooltip right max-width="500" color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
        </template>
        <span
          >Aqui você acompanha o processamento dos arquivos enviados. Para gerar e baixar relatórios, acesse a opção
          "PIS/COFINS" no menu principal
        </span
      >
      </v-tooltip>

      <v-spacer />
      <v-spacer />
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar..." single-line hide-details dense />
    </v-card-title>
    <v-flex md12 style="overflow: hidden">
      <v-data-table
        :loading="loading"
        loading-text="Carregando informações..."
        show-expand
        :headers="headers"
        :items="uploads"
        :search="search"
        :sort-by="'created_at'"
        :sort-desc="true"
        :custom-sort="customSort"
        :must-sort="true"
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
        }"
      >
        <template v-slot:item.fileNameSliced="{ item }">
          <slot name="idCol">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-icon left>mdi-folder-zip-outline</v-icon>
                  {{ item.fileNameSliced }}
                </span>
              </template>
              <span>{{ item.fileName }}</span>
            </v-tooltip>
          </slot>
        </template>

        <template v-slot:item.amountOfDocuments="{ item }">
          <v-chip color="darkgrey" dark class="blink" label>
            {{ item.amountOfDocuments }}
          </v-chip>
        </template>

        <template v-slot:item.statusMessage="{ item }">
          <v-chip :color="item.statusColor" dark class="blink">
            {{ item.statusMessage }}
          </v-chip>
        </template>

        <template v-slot:expanded-item="{ headers, item: childItem }">
          <td :colspan="headers.length">
            <v-data-table dense hide-default-footer :headers="detailHeaders" :items="childItem.details" :sort-by="'establishment'">
              <template v-slot:item.subStatusMessage="{ item }">
                <v-chip :color="item.subStatusColor" style="color: #393939" small class="blink">
                  {{ item.subStatusMessage }}
                </v-chip>
              </template>

              <template v-slot:item.fileName="{ item }">
                <slot name="fileNameCol">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon left small>mdi-file-document-outline</v-icon>
                        {{ item.fileName }}
                      </span>
                    </template>
                    <span>{{ item.file_name }}</span>
                  </v-tooltip>
                </slot>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </v-flex>

    <v-dialog v-model="errorDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 white--text error">
          <v-icon left class="white--text">mdi-alert</v-icon>
          Algo deu errado
        </v-card-title>
        <br />
        <v-card-text class="text-h6">
          Tente novamente mais tarde ou entre em contato com o administrador
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text class="secondary lighten-2" @click="errorDialog = false" id='IdBtnClose'> fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </v-card>
</template>

<script>
import 'moment/locale/pt-br';
import gedService from '@/services/gedService.js';
import pisCofinsService from '@/services/pisCofinsService.js';
import moment from 'moment';

export default {
  name: 'TableStatusUploadsPisCofins',

  props: {
    message: {
      type: String,
    },
  },

  data() {
    return {
      loading: false,
      search: '',
      error: false,
      errorDialog: false,

      headers: [
        {
          text: 'Nome do arquivo',
          align: 'left',
          sortable: false,
          value: 'fileNameSliced',
        },
        {
          text: 'Qtd. de documentos',
          value: 'amountOfDocuments',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Data do upload',
          value: 'date',
          align: 'left',
        },
        {
          text: 'Usuário',
          value: 'user',
          align: 'left',
        },
        {
          text: 'Status',
          value: 'statusMessage',
          align: 'center',
        },
      ],

      detailHeaders: [
        {
          text: 'Nome do documento',
          align: 'left',
          sortable: false,
          value: 'fileName',
        },
        {
          text: 'Tipo',
          value: 'typeTranslated',
          align: 'left',
        },
        {
          text: 'Estabelecimento',
          value: 'estabFormatted',
          align: 'left',
        },
        {
          text: 'Status',
          value: 'subStatusMessage',
          align: 'center',
        },
      ],

      uploads: [],

      statusDict: {
        // Arquivos ZIP
        'Request recebido': {
          message: 'Aguardando processamento',
          color: '#4d81da', // azul
        },

        'Parsing Files': {
          message: 'Processando arquivos',
          color: '#4d81da', // azul
        },

        CannotParseAll: {
          message: 'Finalizado: Não é possível processar todos os documentos',
          color: '#ffb900', // amarelo escuro
        },

        CouldNotParseAll: {
          message: 'Finalizado: Não foi possível processar todos os documentos',
          color: '#ffb900', // amarelo escuro
        },

        InconsistencyFoundNotAllParsed: {
          message: 'Finalizado: Inonsistências encontradas',
          color: '#ffb900', // amarelo escuro
        },

        ParseERROR: {
          message: 'Finalizado: Erro',
          color: '#de2c1f', // vermelho escuro
        },

        AllParsed: {
          message: 'Finalizado: Todos os documentos foram processados',
          color: '#459f48', // verde escuro
        },
        'Wrong File Type': {
          message: 'Erro: Formato de arquivo inválido',
          color: '#de2c1f', // vermelho escuro
        },
        'File Download Failed': {
          message: 'Erro: Falha no upload do arquivo',
          color: '#de2c1f', // vermelho escuro
        },

        // Documentos txt
        CouldNotParse: {
          message: 'Erro',
          color: '#f27474', // vermelho claro
        },

        CannotParse: {
          message: 'Erro',
          color: '#f27474', // vermelho claro
        },

        InconsistencyFound: {
          message: 'Erro: Inconstência encontrada',
          color: '#f27474', // vermelho claro
        },

        WaitingForParse: {
          message: 'Aguardando processamento',
          color: '#b6d1ff', // azul claro
        },

        Duplicated: {
          message: 'Documento duplicado',
          color: '#afafaf', // cinza
        },

        ParsedLocally: {
          message: 'Em processamento',
          color: '#b6d1ff', // azul claro
        },

        Rectified: {
          message: 'Documento descartado',
          color: '#afafaf', // cinza
        },

        Parsed: {
          message: 'Documento processado com sucesso',
          color: '#a5dc86', // verde claro
        },

        Deleted: {
          message: 'Documento excluído',
          color: '#afafaf', // cinza
        },
      },

      documentsNameDict: {
        'EFD Contribuicoes': 'EFD Contribuições',
      },

      gedNames: {},
    };
  },

  mounted() {
    this.getAll();

    let autoUpdateTable2 = window.setInterval(() => this.getAll(), 2000) // 2 segundos
    let autoUpdateTable10 = window.setInterval(() => this.getAll(), 10000) // 10 segundos
    window.setTimeout(() => clearInterval(autoUpdateTable2), 4000) // 4 segundos
    window.setTimeout(() => clearInterval(autoUpdateTable10), 600000) // 10 minutos
  },

  created() {
    this.init();
  },

  destroyed() {
    clearInterval(this.autoUpdateTable2);
    clearInterval(this.autoUpdateTable10);
  },

  methods: {
    init() {
      this.$on('refresh', this.open);
    },

    open() {
      this.getAll();
    },

    async getGedFileName(id) {
      const gedMetaResponse = await gedService.meta.get(id);
      const gedFileName = gedMetaResponse.data.name;
      return gedFileName;
    },

    async getAll() {
      try {
        this.loading = true;
        let response = await pisCofinsService.file_process_detail_list.getAll();
        this.error = false;
        this.uploads = response.data;
        this.uploads.map((u) => {
          u.fileName = `Carregando ...`;
          if (!Object.keys(this.gedNames).includes(String(u.ged_id))) {
            this.getGedFileName(u.ged_id)
              .then((n) => (this.gedNames[u.ged_id] = n))
              .catch((e) => console.log(e));
          }
          if (this.gedNames[u.ged_id]) u.fileName = this.gedNames[u.ged_id];

          u.fileName.length > 20 && u.fileName !== `Carregando ...`
            ? (u.fileNameSliced = u.fileName.slice(0, 20) + ` ...`)
            : (u.fileNameSliced = u.fileName);

          u.date = moment(u.created_at).format('DD/MM/YYYY HH:mm:ss');
          u.amount_of_documents ? (u.amountOfDocuments = u.amount_of_documents) : (u.amountOfDocuments = '-');

          if (Object.keys(this.statusDict).includes(u.status_process)) {
            u.statusMessage = this.statusDict[u.status_process].message;
            u.statusColor = this.statusDict[u.status_process].color;
          } else {
            u.statusMessage = u.status_process;
            u.statusColor = '#afafaf'; // cinza
          }

          u.details.map((uDetails) => {
            uDetails.fileName = uDetails.file_name.slice(0, 43) + ` ...`;
            uDetails.estabFormatted = uDetails.establishment.replace(
              /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              '$1.$2.$3/$4-$5',
            );

            if (Object.keys(this.documentsNameDict).includes(uDetails.type)) {
              uDetails.typeTranslated = this.documentsNameDict[uDetails.type]
            } else {
              uDetails.typeTranslated = uDetails.type
            }

            if (Object.keys(this.statusDict).includes(uDetails.status_sub_process)) {
              uDetails.subStatusMessage = this.statusDict[uDetails.status_sub_process].message;
              uDetails.subStatusColor = this.statusDict[uDetails.status_sub_process].color;
            } else {
              uDetails.subStatusMessage = uDetails.status_sub_process;
              uDetails.subStatusColor = '#afafaf'; // cinza
            }
          });
        });
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorDialog = true;
      }
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
          if (index[0]=='date') {
            if (!isDesc[0]) {
                return new Date(b['created_at']) - new Date(a['created_at']);
            } else {
                return new Date(a['created_at']) - new Date(b['created_at']);
            }
          }
          else {
            if(typeof a[index] !== 'undefined'){
              if (!isDesc[0]) {
                 return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
              }
              else {
                  return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
              }
            }
          }
      });
      return items;
    }
  },
};
</script>

<style scoped>
.blink {
  -webkit-animation: blink-effect 0.75s linear infinite;
  -moz-animation: blink-effect 0.75s linear infinite;
  -ms-animation: blink-effect 0.75s linear infinite;
  -o-animation: blink-effect 0.75s linear infinite;
  animation: blink-effect 0.75s linear infinite;
}
</style>
